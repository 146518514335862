import React from 'react';
import axios from "axios";
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import  '../assets/scss/style.scss';
const baseURL = "https://staged-projects.com/reactwp/wp-json/reactwp/v1/get-home";

const Home = (props) => {

 console.log("props data");
 console.log(props.pagedata);
  return (
    <>
      <Hero banner={props.pagedata.banner_section} className="illustration-section-01" />
      <FeaturesTiles section={props.pagedata.section_2}
 />
      <FeaturesSplit section={props.pagedata.section_3} invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial testimonial={props.pagedata.testimonials} topDivider />
      <section id="contact" className="subscribe-section pt-120 pb-120">
      <div className="container">
        <div className="subscribe-wrapper img-bg">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title mb-15">
                <h2 className="text-white mb-25">Subscribe Our Newsletter</h2>
                <p className="text-white pr-5">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5">
              <form action="#" className="subscribe-form">
                <input
                  type="email"
                  name="subs-email"
                  id="subs-email"
                  placeholder="Your Email"
                />
                <button type="submit" className="main-btn btn-hover">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Home;