import React from 'react';
import axios from "axios";
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Image from '../components/elements/Image';
import Cta from '../components/sections/Cta';
import '../assets/css/bootstrap.min.css';
import '../assets/css/lineicons.css';
import '../assets/css/animate.css';
import '../assets/css/main.css';
const baseURL = "https://staged-projects.com/reactwp/wp-json/reactwp/v1/get-home";

const Inner = (props) => {

 console.log("props data");
 console.log(props.pagedata);
 const page_body=props.pagedata
 const page_body_content=page_body.body;
 if(page_body.page_type=="page")
 {
	const banner=page_body_content.inner_page_banner_section_1;
	const section1=page_body_content.inner_page_section_2;
	const section2=page_body_content.inner_page_section_3;
	const section3=page_body_content.inner_page_section_4;
  return (
    <>
     <section id="home" className="hero-section">
      <div className="container">
        <div className="row align-items-center position-relative">
          <div className="col-lg-6">
            <div className="hero-content">
              <h1   data-reveal-delay="200" className="reveal-from-bottom" dangerouslySetInnerHTML={ {__html: banner.sub.banner_title.value} } >
              
              </h1>
              <p className="reveal-from-bottom" data-wow-delay=".6s" data-reveal-delay="400" dangerouslySetInnerHTML={ {__html: banner.sub.banner_content.value} }>
               
              </p>
              <a
                href={banner.sub.button_link_1_.value} target={banner.sub.button_target.value}
                className="main-btn border-btn btn-hover reveal-from-bottom"
               data-reveal-delay="200"
                >{banner.sub.button_1_text.value}</a
              >
              <a href="#features" className="scroll-bottom">
                <i className="lni lni-arrow-down"></i
              ></a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero-img reveal-from-bottom" data-wow-delay=".5s" data-reveal-delay="800">
              <Image src={banner.sub.banner_image.value} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section id="features" className="feature-section pt-120">
      <div className="container">
        <div className="row justify-content-center">
		{section1.sub.grid_category_1
.all_posts.map((section_posts, index) => (  
          <div className="col-lg-4 col-md-8 col-sm-10 reveal-from-bottom">
            <div className="single-feature">
              <div className="icon">
                <i className={'lni ' + section_posts.icon} ></i>
              </div>
              <div className="content">
                <h3>{section_posts.post.post_title}</h3>
                <p>
                {section_posts.excerpt}
                </p>
              </div>
            </div>
          </div>
		   ))}  
         
        </div>
      </div>
    </section>
   {section2.sub.list_category_1
.all_posts.map((section_posts, index) => (  



    <section id="about" className="about-section pt-150">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 reveal-from-left">
            <div className="about-img"> 
			
			 <Image src={section_posts.featured_image} alt="" className="w-100" />
			 
			  <Image src={require('./../assets/img/about/about-left-shape.svg')} className="shape-1" alt="" />
			  
			   <Image src={require('./../assets/img/about/left-dots.svg')} className="shape-2" alt="" />
              
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 reveal-from-bottom">
            <div className="about-content">
              <div className="section-title mb-30">
                <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                 {section_posts.post.post_title}
                </h2>

                <p className="wow fadeInUp" data-wow-delay=".4s">
                 {section_posts.excerpt}.
                </p>
              </div>
              <a
                href="javascript:void(0)"
                className="main-btn btn-hover border-btn wow fadeInUp"
                data-wow-delay=".6s"
                >Discover More</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>


	

      ))}  
    
   
    <section id="why" className="feature-extended-section pt-100">
      <div className="feature-extended-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-8 col-md-9">
              <div className="section-title text-center mb-60">
                <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                  {section3.sub.section_title_.value}
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  {section3.sub.section_content.value}
                </p>
              </div>
            </div>
          </div>

          <div className="row">
		   {section3.sub.grid_category_2
.all_posts.map((section_posts, index) => ( 
            <div className="col-lg-4 col-md-6">
              <div className="single-feature-extended reveal-from-bottom">
                <div className="icon">
                  <i  className={'lni ' + section_posts.icon}></i>
                </div>
                <div className="content">
                  <h3>{section_posts.post.post_title}</h3>
                  <p>
                   {section_posts.excerpt}.
                  </p>
                </div>
              </div>
            </div>
			
			))}
            
          </div>
        </div>
      </div>
    </section>
   <section id="contact" className="subscribe-section pt-120 pb-120">
      <div className="container">
        <div className="subscribe-wrapper img-bg">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title mb-15">
                <h2 className="text-white mb-25">Subscribe Our Newsletter</h2>
                <p className="text-white pr-5">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5">
              <form action="#" className="subscribe-form">
                <input
                  type="email"
                  name="subs-email"
                  id="subs-email"
                  placeholder="Your Email"
                />
                <button type="submit" className="main-btn btn-hover">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    
    </>
  );
  
 }
  else if(page_body.page_type=="category")
 {
	 const cat_posts=page_body_content.sub;
	 const cat_obj=page_body_content.main;
	 return (
    <>
	<section id="home" className="hero-section blog">
      <div className="container">
        <div className="row align-items-center position-relative">
          <div className="col-lg-6">
            <div className="hero-content" >
              <h1 className="wow fadeInUp" data-wow-delay=".4s" data-reveal-delay="200" className="reveal-from-bottom">
                Welcome To Our BLog
              </h1>
              <p className="wow fadeInUp" data-wow-delay=".6s" data-reveal-delay="200" className="reveal-from-bottom">
                Please, purchase full version to get all sections, features and
                permission to remove footer credit.
              </p>
              
              <a href="#features" className="scroll-bottom">
                <i className="lni lni-arrow-down"></i
              ></a>
            </div>
          </div>
          <div className="col-lg-6" >
            <div className="hero-img blog-hero-img reveal-from-bottom" data-reveal-delay="200" data-wow-delay=".5s" >
             <Image src={require('./../assets/img/blog/blog-hero.jpg')}  />
            </div>
          </div>
        </div>
      </div>
    </section>
	<section className="blog-listing-section">
        <div className="container py-5">
            <div className="blog-content">
                <div className="row g-5">
              
                    <div className="col-lg-8">
                        <div className="row g-5">
						 {cat_posts
.all_posts.map((section_posts, index) => ( 
                            <div className="col-md-6 reveal-from-bottom"  >
                                <div className="blog-item bg-light rounded overflow-hidden">
                                    <div className="blog-img position-relative overflow-hidden">
                                        
										
										 <Image src={section_posts.featured_image} className="img-fluid" alt=""  />
                                        <a className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Web Design</a>
                                    </div>
                                    <div className="p-4">
                                        <div className="d-flex mb-3">
                                            <small className="me-3"><i className="far fa-user text-primary me-2"></i>John Doe</small>
                                            <small><i className="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2045</small>
                                        </div>
                                        <h4 className="mb-3">{section_posts.post.post_title}</h4>
                                        <p>{section_posts.excerpt}.</p>
                                        <a className="text-uppercase" href={"/"+section_posts.post.post_name}>Read More <i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
							))}
                            
                            
                        </div>
                    </div>
                   
                    <div className="col-lg-4">
                      
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                            <div className="input-group">
                                <input type="text" className="form-control p-3" placeholder="Keyword" />
                                <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                            </div>
                        </div>
                        
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Categories</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Design</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Development</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Development</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Keyword Research</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Email Marketing</a>
                            </div>
                        </div>
                        
                        <div className="mb-5 wow slideInUp recent-post" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Recent Post</h3>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                               <Image src={require('./../assets/img/blog/blog-1.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                               <Image src={require('./../assets/img/blog/blog-2.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                                <Image src={require('./../assets/img/blog/blog-3.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                             <Image src={require('./../assets/img/blog/blog-1.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                             <Image src={require('./../assets/img/blog/blog-2.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                               <Image src={require('./../assets/img/blog/blog-3.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                        </div>
                       
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s" >
                         
							 <Image src={require('./../assets/img/blog/blog-1.jpg')} className="img-fluid" alt=""  />
                        </div>
                    
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Tag Cloud</h3>
                            </div>
                            <div className="d-flex flex-wrap m-n1 tag-cloud">
                                <a href="" className="btn btn-light m-1">Design</a>
                                <a href="" className="btn btn-light m-1">Development</a>
                                <a href="" className="btn btn-light m-1">Marketing</a>
                                <a href="" className="btn btn-light m-1">SEO</a>
                                <a href="" className="btn btn-light m-1">Writing</a>
                                <a href="" className="btn btn-light m-1">Consulting</a>
                                <a href="" className="btn btn-light m-1">Design</a>
                                <a href="" className="btn btn-light m-1">Development</a>
                                <a href="" className="btn btn-light m-1">Marketing</a>
                                <a href="" className="btn btn-light m-1">SEO</a>
                                <a href="" className="btn btn-light m-1">Writing</a>
                                <a href="" className="btn btn-light m-1">Consulting</a>
                            </div>
                        </div>
                     
                        <div className="wow slideInUp" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Plain Text</h3>
                            </div>
                            <div className="bg-light text-center text-widget" >
                                <p>Vero sea et accusam justo dolor accusam lorem consetetur, dolores sit amet sit dolor clita kasd justo, diam accusam no sea ut tempor magna takimata, amet sit et diam dolor ipsum amet diam</p>
                                <a href="" className="btn btn-primary py-2 px-4">Read More</a>
                            </div>
                        </div>
                      
                    </div>
                   
                </div>
            </div>
        </div>
    </section>
	  </>
  );
	 
 }
   else if(page_body.page_type=="post")
 {
	  const section_posts=page_body_content.main;
	  return (
    <>
	<section id="home" className="hero-section blog">
      <div className="container">
        <div className="row align-items-center position-relative">
          <div className="col-lg-6">
            <div className="hero-content">
              <h1 className="reveal-from-bottom"  data-reveal-delay="200" className="reveal-from-bottom">
                {section_posts.post.post_title}
              </h1>
              <p data-reveal-delay="200" className="reveal-from-bottom">
                Please, purchase full version to get all sections, features and
                permission to remove footer credit.
              </p>
              
              <a href="#features" className="scroll-bottom">
                <i className="lni lni-arrow-down"></i
              ></a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero-img blog-hero-img wow fadeInUp reveal-from-bottom" data-reveal-delay="200" >
              <Image src={require('./../assets/img/blog/blog-hero.jpg')}  />
            </div>
          </div>
        </div>
      </div>
    </section>


    <section className="blog-listing-section">
        <div className="container py-5">
            <div className="blog-content">
                <div className="row g-5">
                  
                    <div className="col-lg-8">
                      
                        <div className="mb-5 reveal-from-bottom" >
                            <Image src={section_posts.featured_image} className="img-fluid"  alt=""  />
							
						
                            <h1 className="mb-4 text-light">{section_posts.post.post_title}</h1>
                            <p dangerouslySetInnerHTML={ {__html: section_posts.post.post_content} }></p>
                        </div>
                       
                        <div className="mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">0 Comments</h3>
                            </div>
                            
                        </div>
                      
                        
                       
                    </div>
                 
                    <div className="col-lg-4">
                      
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s" >
                            <div className="input-group">
                                <input type="text" className="form-control p-3" placeholder="Keyword" />
                                <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                            </div>
                        </div>
                      
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Categories</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Design</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Development</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Web Development</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Keyword Research</a>
                                <a className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i className="bi bi-arrow-right me-2"></i>Email Marketing</a>
                            </div>
                        </div>
                       
                        <div className="mb-5 wow slideInUp recent-post" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Recent Post</h3>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                               <Image src={require('./../assets/img/blog/blog-1.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                               <Image src={require('./../assets/img/blog/blog-2.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                                <Image src={require('./../assets/img/blog/blog-3.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                             <Image src={require('./../assets/img/blog/blog-1.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                             <Image src={require('./../assets/img/blog/blog-2.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                            <div className="d-flex rounded overflow-hidden mb-3 w-100">
                               <Image src={require('./../assets/img/blog/blog-3.jpg')} className="img-fluid" alt=""  />
                                <a href="" className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet adipis elit
                                </a>
                            </div>
                        </div>
                       
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s" >
                            <img src="img/blog-1.jpg" alt="" className="img-fluid rounded" />
                        </div>
                      
                        <div className="mb-5 wow slideInUp" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Tag Cloud</h3>
                            </div>
                            <div className="d-flex flex-wrap m-n1 tag-cloud">
                                <a href="" className="btn btn-light m-1">Design</a>
                                <a href="" className="btn btn-light m-1">Development</a>
                                <a href="" className="btn btn-light m-1">Marketing</a>
                                <a href="" className="btn btn-light m-1">SEO</a>
                                <a href="" className="btn btn-light m-1">Writing</a>
                                <a href="" className="btn btn-light m-1">Consulting</a>
                                <a href="" className="btn btn-light m-1">Design</a>
                                <a href="" className="btn btn-light m-1">Development</a>
                                <a href="" className="btn btn-light m-1">Marketing</a>
                                <a href="" className="btn btn-light m-1">SEO</a>
                                <a href="" className="btn btn-light m-1">Writing</a>
                                <a href="" className="btn btn-light m-1">Consulting</a>
                            </div>
                        </div>
                     
                        <div className="wow slideInUp" data-wow-delay="0.1s" >
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="mb-0">Plain Text</h3>
                            </div>
                            <div className="bg-light text-center text-widget" >
                                <p>Vero sea et accusam justo dolor accusam lorem consetetur, dolores sit amet sit dolor clita kasd justo, diam accusam no sea ut tempor magna takimata, amet sit et diam dolor ipsum amet diam</p>
                                <a href="" className="btn btn-primary py-2 px-4">Read More</a>
                            </div>
                        </div>
                      
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
	  </>
  );
	 
 }
 else
 {
	 
	 return null;
 }
 

}

export default Inner;