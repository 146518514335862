import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import axios from "axios";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutInner from './layouts/LayoutInner';

// Views 
import Home from './views/Home';
import Inner from './views/Inner';


const baseURL = "https://staged-projects.com/reactwp/wp-json/reactwp/v1/get-page";
// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();
 const [pagepath, setPagePath] = React.useState(null);
  useEffect(() => {
	  var slug=location.pathname;
   if(slug.endsWith('/'))
   slug=slug.slice(0, -1);
     if(slug.startsWith('/'))
  slug=slug.slice(1);
   // alert(slug);
	    axios.get(baseURL,{ params: { slug: slug } }).then((response) => {
		console.log(response.data);
      setPagePath(response.data);
	  document.body.classList.add('is-loaded')
   childRef.current.init();
   const page = location.pathname;
  
    trackPage(page);
    });
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  if(!pagepath)
  return null;

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} pagedata={pagepath.body} layout={LayoutDefault} />
		   <AppRoute exact path="/:slug" component={Inner} pagedata={pagepath} layout={LayoutInner} />
		   <AppRoute exact path="/category/:slug" component={Inner} pagedata={pagepath} layout={LayoutInner} />
        </Switch>
      )} />
  );
}

export default App;